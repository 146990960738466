import React, { useState } from 'react';
import {
    Tab,
    Tabs,
    TabPanels,
    TabPanel,
    TabList,
    Divider,
    Flex,
    Text
} from '@chakra-ui/react';
import { list } from '../../resource';

const TableMobile = () => {
    const [activeTab, setActiveTab] = useState(1);

    return (
        <Flex flexDirection={"column"}>
            <Tabs id='planes-pago' variant='enclosed' flexDirection={"column"} alignItems={"center"}>
                <TabList width={"max-content"} flexDirection={"column"} margin={"auto"}>
                    <Tab fontFamily={"Larsseit"} borderColor={"#FFF"} pl={10} pr={10} paddingBottom={0}
                        _selected={{ borderColor: "#FFF", borderBottomColor: "#DADADA" }} onClick={() => { setActiveTab(1)}}>
                            {activeTab === 1 ?
                                <Text fontFamily={"Larsseit-Bold"} p={"10px 22px"} fontSize={"20px"} bgColor={"#545454"} color={"#FFF"} borderRadius={"9px"}>Contado</Text>
                            :   
                                <Text fontFamily={"Larsseit"} fontSize={"18px"}>Contado</Text>
                            }
                    </Tab>
                    <Tab mt={2} borderBottomColor={"#FFF"} pl={10} pr={10} paddingBottom={0}
                        _selected={{ borderColor: "#FFF", borderBottomColor: "#DADADA" }} onClick={() => { setActiveTab(2)}}>
                            {activeTab === 2 ?
                                <Text fontFamily={"Larsseit-Bold"} p={"10px 22px"} fontSize={"20px"} bgColor={"#545454"} color={"#FFF"} borderRadius={"9px"}>Contado diferido</Text>
                            :   
                                <Text fontFamily={"Larsseit"} fontSize={"18px"}>Contado diferido</Text>
                            }
                    </Tab>
                    <Tab mt={2} borderColor={"#FFF"} pl={10} pr={10} paddingBottom={0}
                        _selected={{ borderColor: "#FFF", borderBottomColor: "#DADADA" }} onClick={() => { setActiveTab(3)}}>
                            {activeTab === 3 ?
                                <Text fontFamily={"Larsseit-Bold"} p={"10px 22px"} fontSize={"20px"} bgColor={"#545454"} color={"#FFF"} borderRadius={"9px"}>Financiamiento A</Text>
                            :   
                                <Text fontFamily={"Larsseit"} fontSize={"18px"}>Financiamiento A</Text>
                            }
                    </Tab>
                    <Tab mt={2} borderColor={"#FFF"} pl={10} pr={10} paddingBottom={0}
                        _selected={{ borderColor: "#FFF", borderBottomColor: "#DADADA" }} onClick={() => { setActiveTab(4)}}>
                            {activeTab === 3 ?
                                <Text fontFamily={"Larsseit-Bold"} p={"10px 22px"} fontSize={"20px"} bgColor={"#545454"} color={"#FFF"} borderRadius={"9px"}>Financiamiento B</Text>
                            :   
                                <Text fontFamily={"Larsseit"} fontSize={"18px"}>Financiamiento B</Text>
                            }
                    </Tab>
                </TabList>
                <TabPanels mt={10}>
                    {list.map((item, idx) => (
                        <TabPanel fontFamily={"Montserrat, sans-serif"} key={idx}>
                            <Flex flexDirection={"column"}>
                                <Flex flexDirection={"column"}>
                                    <Text fontFamily={"Larsseit-Bold"} fontSize={"16px"} textTransform={"capitalize"} color={"#545454"}>{`Descuento ${item.num}`}</Text>
                                    <Text fontFamily={"Larsseit-Bold"} fontSize={"16px"} mb={5} mt={2}>{item.pay}</Text>
                                    <Divider />
                                </Flex>
                            </Flex>
                            <Flex flexDirection={"column"}>
                                {item.tab.map((element, idx1) => (
                                    <Flex key={idx1} flexDirection={"column"} lineHeight={2}>
                                        <Text fontFamily={"Larsseit"} color={"#545454"} fontSize={"16px"} textTransform={"inherit"}>{element.item1}</Text>
                                        <Text fontFamily={"Larsseit"} color={"#545454"} fontSize={"16px"} textTransform={"inherit"}>{element.item2}</Text>
                                        <Divider />
                                    </Flex>
                                ))}
                            </Flex>
                        </TabPanel>            
                    ))}
                </TabPanels>
            </Tabs>
            <Text mt={5} textAlign={"center"} fontFamily={"Larsseit"} fontSize={"12px"}>
                *LA PRESENTE POLITICA COMERCIAL LA CUAL DETALLA LAS DIFERENTES FORMAS DE PAGO ESTA SUJETA A CAMBIOS EN CUALQUIER MOMENTO Y SIN PREVIO AVISO EN FUNCIÓN DE LA DEMANDA, VALIDA SOLO PARA EL
                MOMENTO DE CONSULTA, este documento no es ni sustituye una cotización, el precio total de la operación se determinará en función de los montos variables por conceptos de descuentos, intereses, créditos directos o
                hipotecarios participantes así como gastos notariales según apliquen, los cuales pueden ser consultados y cotizados por su asesor inmobiliario de Grupo Guía”. para más información consulta nuestra pagina web www.grupoguia.mx , precios en M.N (MONEDA NACIONAL).*
            </Text>
        </Flex>
    );
}
 
export default TableMobile;