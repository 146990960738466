import React, { useEffect } from 'react';
import { 
    Flex, 
    Text,
    useMediaQuery, 
    useTheme,
    Grid,
    GridItem,
    Image
} from '@chakra-ui/react';

const Amenidades = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    console.log(breakpoints.lg)

    useEffect(() => {
        const observerL = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show-amenidades');
          } else {
            entry.target.classList.remove('show-amenidades');
          }
        })
        })
          
        const hiddenElementsL = document.querySelectorAll('.hidden-left-amenidades');
        hiddenElementsL.forEach((el) => observerL.observe(el));
    }, []);

    return ( 
        <Flex paddingTop={isGreaterThanMd?"6rem":4} flexDirection={"column"} paddingBottom={"2rem"} w='100%' id='presentacion'>
            <Grid templateColumns={isGreaterThanMd ? "repeat(5, 1fr)" : "repeat(2, 1fr)"}  px={isGreaterThanMd?24:8} maxW={'5xl'} margin='auto' pb={14} pt={{base: 24, md: 0}}>
                <GridItem colSpan={isGreaterThanMd?3:2} mb={isGreaterThanMd?0:16} alignSelf='center'>
                    <Flex flexDirection={"column"} py={isGreaterThanMd?20:0} px={isGreaterThanMd?10:2}>
                        <Flex paddingBottom={"2rem"} w={'full'} textAlign={isGreaterThanMd?'start':'justify'}>
                            <Text fontSize='lg' fontWeight={400} lineHeight='1.2' color={isGreaterThanMd ? '#535353' : '#4c0b499b'}>
                                Un nuevo referente del lujo y la exclusividad llega a Guadalajara; Torre MM, el nuevo proyecto vertical del complejo Américas MIL500 promete un estilo de vida premium a todo aquel que lo habite, con espacios generosos y amenidades únicas que te permitirán relajarte y sentirte pleno todos los días.
                            </Text>
                        </Flex>
                        <Flex>
                            <Text fontSize='3xl' fontWeight={700} lineHeight='1.2' color='#545454'>
                                Experimenta el Country
                                <br />
                                Club Lifestyle
                            </Text>
                        </Flex>
                    </Flex>
                </GridItem>
                <GridItem colSpan={2} justifyContent='center'>
                    <Flex paddingLeft={isGreaterThanMd?"2rem":0} justifyContent='center' h='100%'>
                        <Image 
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/description/description-amenidades.png'} 
                            width='100%' 
                            height='auto' 
                            alt='amenidades' 
                            style={{objectFit: 'contain', background: 'center'}}
                        />
                    </Flex>
                </GridItem>
            </Grid>        
        </Flex>
    );
}
 
export default Amenidades;
