import axios from 'axios';
import fileDownload from 'js-file-download';

export const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
}

export const list = [
  {
      bold: true,
      num: "15%",
      pay: "7 PAGOS",
      tab: [
          { item1: "· A la firma", item2: "15%"},
          { item1: "· A los 30 días", item2: "15%"},
          { item1: "· A los 60 días", item2: "15%"},
          { item1: "· A los 90 días", item2: "15%"},
          { item1: "· A los 120 días", item2: "15%"},
          { item1: "· A los 150 días", item2: "15%"},
          { item1: "· Último pago contra escritura 1 exhibición", item2: "10%"},
      ]
  },
  {
      bold: false,
      num: "8%",
      pay: "27 PAGOS",
      tab: [
          { item1: "· A la firma", item2: "10%"},
          { item1: "· A los 30 días", item2: "10%"},
          { item1: "· A los 60 días", item2: "10%"},
          { item1: "· A los 90 días diferido durante la obra", item2: "60%"},
          { item1: "· Último pago contra escritura 1 exhibición", item2: "10%"}
      ]
  },
  {
      bold: false,
      num: "3%",
      pay: "28 PAGOS",
      tab: [
        { item1: "· A la firma", item2: "10%"},
        { item1: "· A los 30 días", item2: "10%"},
        { item1: "· A los 60 días diferido durante la obra", item2: "25%"},
        { item1: "· Último pago contra escritura 1 exhibición", item2: "55%"}
    ]
  },
  {
    bold: false,
    num: "0%",
    pay: "28 PAGOS",
    tab: [
      { item1: "· A la firma", item2: "10%"},
      { item1: "· A los 30 días diferido durante la obra", item2: "20%"},
      { item1: "· Último pago contra escritura 1 exhibición", item2: "70%"}
  ]
}
];