import { Icon } from "@chakra-ui/icons";

const ArrowLeftIcon = (props) => (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 16l-4-4m0 0l4-4m-4 4h18"
      />
    </Icon>
);
  
export default ArrowLeftIcon;