import React, { useState } from 'react';
import { Flex, Text, useMediaQuery, useTheme, IconButton } from '@chakra-ui/react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { listImages } from '../resource/list';

import '../styles/styled.css';

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const Recorrido = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [activeIndex, setActiveIndex] = useState(0);
    
    const slidePrev = () => {
        activeIndex===0?(
          setActiveIndex(listImages[2].images.length-1)):
        setActiveIndex(activeIndex - 1);
    };
    
    const slideNext = () => {
        activeIndex===listImages[2].images.length-1?(
          setActiveIndex(0))
            : setActiveIndex(activeIndex + 1)
    
    };
    
    return ( 
        <Flex className='wrapper-all-sections-carrusel-alice' flexDirection={"column"} alignItems={"center"} marginBottom={isGreaterThanMd?"6rem":0} id='amenidades'>
            <Flex flexDirection={"column"} alignItems={"center"}>
                {isGreaterThanMd ?
                    <Text 
                        fontWeight={"400"} 
                        textTransform={"uppercase"} 
                        fontSize={{base:'3xl', md:'5xl'}}
                        mb={0} 
                        fontFamily={'CaslonCP'}
                        textAlign={'center'}
                        color={'#545454'}
                    >
                        Exclusively yours to enjoy
                    </Text>
                :
                    <Text 
                        fontWeight={"400"} 
                        textTransform={"uppercase"} 
                        fontSize={{base:'3xl', md:'5xl'}}
                        mb={0} 
                        fontFamily={'CaslonCP'}
                        textAlign={'center'}
                        color={'#545454'}
                    >
                        Exclusively yours<br />to enjoy
                    </Text>
                }
                {isGreaterThanMd ?
                    <Text mt={2} color={"#535353"} fontSize={'3xl'} px={4} fontWeight={400}>
                        Amenidades exclusivas para un estilo de vida de primera categoría
                    </Text> :
                    <Text textAlign={"center"} mt={2} color={"#535353"} fontSize={'lg'} px={4} fontWeight={400}>
                        Amenidades exclusivas para un estilo de vida <br/>de primera categoría
                    </Text>
                }
                
            </Flex>
            <Flex className='section-carrusel-mons' justifyContent={"center"} flexDirection={"row"} width={isGreaterThanMd?"100%":"90%"} marginTop={isGreaterThanMd?"2rem":0} alignItems={"center"}>
                <IconButton
                    icon={<ChevronLeftIcon width="64.000000pt" height="54.000000pt"/>}
                    border="0"
                    className='btn-carrusel-mons-izq'
                    background={"transparent"}
                    transitionDuration=".3s"
                    onClick={slidePrev}
                    position="relative"
                    left={{ base: "16", md: 40 }}
                    _hover={{ background: "transparent" }}
                    zIndex="2"
                />
                <AliceCarousel
                    mouseTracking
                    animationDuration={900}
                    disableButtonsControls
                    activeIndex={activeIndex}
                    items={listImages[2].images}
                    responsive={true}
                    controlsStrategy="responsive"
                    animationType='fadeout'
                    autoPlay={true}
                    autoPlayInterval={5000}
                    infinite={true}
                    keyboardNavigation={true}
                    disableDotsControls
                    autoHeight={false}
                    maxHeight="200px"
                />
                <IconButton
                    icon={<ChevronRightIcon width="64.000000pt" height="54.000000pt"/>}
                    border="0"
                    className='btn-carrusel-mons-drc'
                    background={"transparent"}
                    transitionDuration=".3s"
                    onClick={slideNext}
                    position="relative"
                    left={{ base: "-16", md: -40 }}
                    _hover={{ background: "transparent" }}
                    zIndex="2"
                />
            </Flex>
            <Flex>
                <Text 
                    fontWeight={300}
                    color='#535353'
                    fontSize={isGreaterThanMd?'xl':'lg'}
                    textAlign={'center'}
                    px={{base:6, md:0}}
                >
                    •Alberca  • Jacuzzi  • Gym  • Sauna  • Vapor  • Área de Masajes  • Social Room  • Business Lounge
                    <br />
                    •Juegos Infantiles  • Pet Shower  • Fire Pit
                </Text>
            </Flex>
        </Flex>
    );
}
 
export default Recorrido;