import React from 'react';
import { 
    Flex, 
    Text,
    Grid,
    GridItem,
    Image,
    useMediaQuery, 
    useTheme
} from '@chakra-ui/react';
import './animation.css';
import IconsMb from './iconsMb';

const Icons = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    return ( 
        <div className='sections-amenidades'>
            <div className='hidden-left-amenidades'>
                {isGreaterThanMd ?
                    <Grid templateColumns={"repeat(5, 1fr)"}  px={24} w={'80%'} margin='auto' pb={16} color='#5B5B5F'>
                        <GridItem colSpan={5} justifyItems=''>
                            <Grid templateColumns={"repeat(3, 1fr)"}  px={10} w={'95%'} margin='auto' pb={16} gap={8} justifyItems={'center'}>
                                <GridItem colSpan={1}>
                                    <Flex alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                                        <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/description/torre-bg.svg'} boxSize={'60px'} alt='torres'/>
                                        <Text marginLeft={"0.5rem"} fontSize='sm' fontWeight={500} color='brand.500' textAlign={'start'}>
                                            Complejo <br /> Américas MIL500
                                        </Text>
                                    </Flex>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <Flex alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                                        <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/description/plans.svg'} boxSize={'60px'} alt='metros'/>
                                        <Text marginLeft={"0.5rem"} fontSize='sm' fontWeight={500} color='brand.500' textAlign={'start'}>
                                            Desde 41.57 m<sup>2</sup><br />hasta 143.77 m<sup>2</sup>
                                        </Text>
                                    </Flex>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <Flex alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                                        <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/description/recamaras.svg'} boxSize={'60px'} alt='recamaras'/>
                                        <Text marginLeft={"0.5rem"} fontSize='sm' fontWeight={500} color='brand.500' textAlign={'start'}>
                                            1, 2 y 3 <br /> habitaciones
                                        </Text>
                                    </Flex>
                                </GridItem>
                            </Grid>
                        </GridItem>
                        <GridItem colSpan={5}>
                            <Grid templateColumns={"repeat(4, 1fr)"}  px={10} w={'95%'} margin='auto' pb={16} gap={8} justifyItems='center'>
                                <GridItem colSpan={1} display={'block'}>
                                    <Flex  alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                                        <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/description/conectividad.svg'} boxSize={'60px'} alt='cercanias'/>
                                        <Text marginLeft={"0.5rem"} fontSize='sm' fontWeight={500} color='brand.500' textAlign={'start'}>
                                            Cercanías a lo <br /> mejor de la ciudad
                                        </Text>
                                    </Flex>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <Flex marginRight={"0rem"}  alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                                        <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/description/amenidades.svg'} boxSize={'60px'} alt='amenidades'/>
                                        <Text marginLeft={"0.5rem"} fontSize='sm' fontWeight={500} color='brand.500' textAlign={'start'}>
                                            Más de 20 <br /> amenidades exclusivas
                                        </Text>
                                    </Flex>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <Flex marginRight={"0rem"}   alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                                        <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/description/estacionamiento.svg'} boxSize={'60px'} alt='estacionamiento'/>
                                        <Text marginLeft={"0.5rem"} fontSize='sm' fontWeight={500} color='brand.500' textAlign={'start'}>
                                            Estacionamiento <br /> subterráneo
                                        </Text>
                                    </Flex>
                                </GridItem>
                                <GridItem colSpan={1} placeItems={'end'}>
                                    <Flex marginRight={"0rem"}   alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                                        <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/description/pin.svg'} boxSize={'60px'} alt='ubicacion'/>
                                        <Text marginLeft={"0.5rem"} fontSize='sm' fontWeight={500} color='brand.500' textAlign={'start'}>
                                            Ubicación <br /> premium
                                        </Text>
                                    </Flex>
                                </GridItem>
                            </Grid>
                        </GridItem>
                    </Grid> : <IconsMb />
                }
            </div>
        </div>
    );
}
 
export default Icons;