import React from 'react';
import { Flex, Text, useMediaQuery, useTheme, Image } from '@chakra-ui/react';
import Slider from "react-slick";
import "../styles/styled.css";

const Departamentos = () => {

    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
    const [isGreaterMidThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [isGreaterSmThanMd] = useMediaQuery(`(min-width: ${breakpoints.sm})`);

    function renderSize() {
        if (isGreaterThanMd) {
            return 4;
        }
        else if (isGreaterMidThanMd) {
            return 3;
        } 
        else if (isGreaterSmThanMd) {
            return 2;
        }
        else {
            return 1;
        }
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: renderSize(),
        slidesToScroll: renderSize(),
        rows: 1,
    };

    return ( 
        <Flex flexDirection={"column"} alignItems={"center"} mt={16}>
            <Flex textAlign={"center"} alignItems={"center"}  flexDirection={"column"} width='100%' mb={4}>
                <Text 
                    color={"brand.400"}
                    textTransform={"uppercase"} 
                    fontWeight={"400"} 
                    fontSize={{base:'2xl', md:'5xl'}}
                    fontFamily={'CaslonCP'}
                    px={4}
                >
                    Exclusively yours
                </Text>
                <Text width={isGreaterThanMd ? "100%" : "70%"} color={"#545454"} marginBottom={"2rem"} fontSize={isGreaterThanMd?'3xl':'xl'} fontWeight='400'>
                    Departamentos de 1, 2 y 3 recámaras desde 41.57 m<sup>2</sup> hasta 143.77 m<sup>2</sup>
                </Text>
            </Flex>
            <Flex flexDirection={"column"} width={"75%"} marginBottom={"4rem"}>
                <Slider {...settings}>
                    <div>
                        <Image 
                            className='img-deptos-md' 
                            objectFit='cover' 
                            boxSize='350px' 
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/simple-carousel/sample-1-1.webp'} 
                            alt='icon'
                        />
                    </div>
                    <div>
                        <Image 
                            className='img-deptos-md' 
                            objectFit='cover' 
                            boxSize='350px' 
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/simple-carousel/sample-1-2.webp'} 
                            alt='icon'
                        />
                    </div>
                    <div>
                        <Image 
                            className='img-deptos-md' 
                            objectFit='cover' 
                            boxSize='350px' 
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/simple-carousel/sample-1-3.webp'} 
                            alt='icon'
                        />
                    </div>
                    <div>
                        <Image 
                            className='img-deptos-md' 
                            objectFit='cover' 
                            boxSize='350px' 
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/simple-carousel/sample-1-4.webp'} 
                            alt='icon'
                        />
                    </div>
                    <div>
                        <Image 
                            className='img-deptos-md' 
                            objectFit='cover' 
                            boxSize='350px' 
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/simple-carousel/sample-2-1.webp'} 
                            alt='icon'
                        />
                    </div>
                    <div>
                        <Image 
                            className='img-deptos-md' 
                            objectFit='cover' 
                            boxSize='350px' 
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/simple-carousel/sample-2-2.webp'} 
                            alt='icon'
                        />
                    </div>
                    <div>
                        <Image 
                            className='img-deptos-md' 
                            objectFit='cover' 
                            boxSize='350px' 
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/simple-carousel/sample-2-3.webp'} 
                            alt='icon'
                        />
                    </div>
                    <div>
                        <Image 
                            className='img-deptos-md' 
                            objectFit='cover' 
                            boxSize='350px' 
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/simple-carousel/sample-2-4.webp'} 
                            alt='icon'
                        />
                    </div>
                </Slider>
            </Flex>
        </Flex>
    );
}
 
export default Departamentos;