import { Stack, useMediaQuery, useTheme, } from "@chakra-ui/react"
const Video = ({url}) => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    return (
        <Stack
            my={24}
            alignItems={"center"}
            mx={isGreaterThanMd?'auto':4}
            maxW={'4xl'}
        >
            <iframe width="100%" height="515" src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Stack>
    )
}

export default Video