import React, { useState } from 'react';
import { Flex, Text, useMediaQuery, useTheme, Input, Button, Grid, GridItem, Box, Checkbox, Link } from "@chakra-ui/react";
import "../styles/mapa.css";
import { toast } from 'react-toastify';

const Mapa = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const postForm = () => {
        let data_send = {
            fields: [
              {
                name: "firstname",
                value: name,
              },
              {
                name: "lastname",
                value: lastName,
              },
              {
                name: "email",
                value: email,
              },
              {
                name: "phone",
                value: phone,
              },
            ],
            context: {
              ipAddress: "3.14.97.137",
              pageUri: "www.grupoguia.mx",
              pageName: "Grupo Guia | Torre MM",
            },
          };
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data_send),
          };
          fetch(
            "https://api.hsforms.com/submissions/v3/integration/submit/5930840/4e271e4f-ec48-4a49-98d6-af4b257290c3",
            // "03479824-13fb-4023-a9c1-a35a76088498",
            requestOptions
          )
            .then((response) => response.json())
            .then((data_w) => {
                toast.success("¡Tus datos fueron eviados correctamente!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                setName("");
                setLastName("");
                setEmail("");
                setPhone("");
            });
    }
    
    return ( 
        <Flex w={"100%"}>
            <Flex display={isGreaterThanMd ? "flex" : "none"} w={"40%"} justifyContent={"end"} alignItems={"center"}>
                <Flex 
                    backgroundImage={`url('https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/description/description-img.svg')`}
                    backgroundSize={'cover'}
                    backgroundRepeat={'no-repeat'}
                    backgroundPosition={'center'} width={"70%"} height={"80%"}></Flex>
            </Flex>
            <Flex flexDirection={"column"} w={isGreaterThanMd ? "60%" : "100%"}>
                <Flex justifyContent={"center"}>
                    <Flex flexDirection={"column"} marginBottom={"2rem"} marginTop={"1rem"} width={isGreaterThanMd?"45%":'70%'} textAlign={"center"}>
                        <Text color={"brand.500"} fontSize={isGreaterThanMd?'5xl':'3xl'} fontWeight={"400"} fontFamily={'CaslonCP'} >
                            CONTACTO
                        </Text>
                        <Text color='#545454' fontSize='lg' fontWeight='400'>
                            Si te interesa saber más de Torre MM, déjanos tus datos y uno de nuestros asesores se pondrá en contacto contigo a la brevedad posible.
                        </Text>
                    </Flex>
                </Flex>
                <Flex mb={10} p={"0px 2rem"} display={isGreaterThanMd ? "none" : "flex"} w={"100%"} h={"60vh"} justifyContent={"end"} alignItems={"center"}>
                    <Flex 
                        backgroundImage={`url('https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/description/description-img.svg')`}
                        backgroundSize={'cover'}
                        backgroundRepeat={'no-repeat'}
                        backgroundPosition={'center'} width={"100%"} height={"100%"}></Flex>
                </Flex>
                <Box justifyContent={"center"} id='contacto' mb={10}>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        window.gtag('event', 'formulario_contacto')
                        window.fbq('track', 'Lead', {content_name:'contacto'})
                        postForm();
                    }}>
                        <Grid templateColumns={"repeat(2, 1fr)"} w='60%' maxW={'5xl'} mx='auto' gap={10}>
                            <GridItem colSpan={2}>
                                <Input 
                                    variant='flushed' 
                                    placeholder='Nombre*' 
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    isRequired
                                />
                            </GridItem>
                            <GridItem colSpan={2}>
                                <Input 
                                    variant='flushed'
                                    placeholder='Apellido*'
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)} 
                                    isRequired
                                    />
                            </GridItem>
                            <GridItem colSpan={isGreaterThanMd?1:2}>
                                <Input 
                                    variant='flushed' 
                                    type='number' 
                                    placeholder='Número de teléfono*' 
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    isRequired
                                />
                            </GridItem>
                            <GridItem colSpan={isGreaterThanMd?1:2}>
                                <Input 
                                    variant='flushed' 
                                    type='email' 
                                    placeholder='Correo*' 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    isRequired
                                />
                            </GridItem>
                            <GridItem colSpan={2} textAlign={'center'} mt={4}>
                                <Checkbox>
                                    <Text>
                                        He leído y acepto el 
                                        <Link href='https://grupoguia.mx/Aviso-de-privacidad' color='blue' textDecoration={'underline'} ml={2} isExternal>
                                            Aviso de privacidad
                                        </Link>
                                    </Text>
                                </Checkbox>
                            </GridItem>

                            <GridItem colSpan={2} textAlign={'center'} mt={4}>
                                <Button type='submit' colorScheme='brand.500' px={8} rounded={'none'}>
                                    Enviar
                                </Button>
                            </GridItem>
                        </Grid>
                    </form>
                </Box>
            </Flex>
        </Flex>
    );
}
 
export default Mapa;