import React from 'react';
import { Flex, Text, useMediaQuery, useTheme, Link, Image } from '@chakra-ui/react';

const Ubicacion = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    const assets = [
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/location/map-section.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/location/mobile/map-section-mb.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/location/description-map.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/location/mobile/description-map-mb.svg' }
    ]

    return ( 
        <Flex paddingTop="2rem" marginBottom={"4rem"} justifyContent='center' bg={"#FFF"} py={isGreaterThanMd?4:10}>
            <Flex alignItems={"center"} flexDirection={"column"} color={"#545454"}>
                <Flex alignItems={"center"} textAlign={"center"} flexDirection={"column"} px={4}>
                    <Text 
                        fontWeight={"400"} 
                        textTransform={"uppercase"} 
                        fontSize={{base:'2xl', md:'4xl'}}
                        mb={0} 
                        fontFamily={'CaslonCP'}
                        textAlign={'center'}
                        color={'#545454'}
                    >
                        La mejor ubicación, en lo mejor del Country Club
                    </Text>
                    <Text mt={5} width={isGreaterThanMd?"65%":"100%"} color={"#545454"} fontWeight='400' fontSize={isGreaterThanMd?'2xl':'sm'} lineHeight='1.2'>
                        Ubicado cerca de los servicios más exclusivos de la ciudad: Ubicado en el complejo Américas MIL500, Pannarama, corredores gastronómicos, bares y mucho más.
                    </Text>
                    <Text color={isGreaterThanMd?"brand.500":'white'}  marginTop={"1.5rem"} marginBottom={"1rem"} fontWeight={"500"} fontSize={isGreaterThanMd?'xl':'md'}>
                        
                    </Text>
                    <Flex flexDirection={isGreaterThanMd ? "row" : "column"} justifyContent={"center"} alignItems={"center"} mt={4}>
                        <Image m={isGreaterThanMd ? "0px 1rem" : "1rem"} src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/icon-maps.svg'} width={isGreaterThanMd ? "10" : "20"} height={isGreaterThanMd ? "10" : "20"} alt="descripción" />
                        <Link borderBottom={"2px solid #0882C6"} color={"#0882C6"} fontSize={"16px"} href='https://goo.gl/maps/8X25fy4hmjriLaaNA' isExternal>
                            <span>Mar Mediterráneo 1069, Country Club, 44610, Guadalajara, Jalisco.</span>
                        </Link>
                    </Flex>
                </Flex>
                <Flex justifyContent={"center"} mt={5} flexDirection={"column"}>
                    <img src={isGreaterThanMd ? assets[0].imgUrl : assets[1].imgUrl } style={{ width: "100%", height: 'auto' }} width="500" height="500" alt='cercanias' />
                    <Flex justifyContent={"center"} mt={10}>
                        <img src={isGreaterThanMd ? assets[2].imgUrl : assets[3].imgUrl } style={{ width: isGreaterThanMd ? "60%" : "100%", height: 'auto' }} width="500" height="500" alt='cercanias' />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}
 
export default Ubicacion;
