import React from 'react';
import { 
    Flex,
    useMediaQuery, 
    useTheme,
    Text,
    Stack,
    Grid,
    GridItem,
} from "@chakra-ui/react";

import "../styles/fondo.css";

import ButtonActionModal from './ButtonActionModal';

const Fondo2 = ({ imgBg, titleTop, titleBottom }) => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [isGreaterThanXl] = useMediaQuery(`(min-width: ${breakpoints.xl})`);

    return ( 
        <Flex marginBottom={"4rem"} alignItems={'self-end'}>
            <Grid templateColumns={{base: 'repeat(3, 1fr)' ,md: 'repeat(3, 1fr)'}} w='full' minH={isGreaterThanMd?'65vh':'45.5vh'}>
                <GridItem colSpan={3} >
                    <div style={{ backgroundImage: `url('${imgBg}')`, alignItems: "center", backgroundSize: isGreaterThanXl?'cover':(isGreaterThanMd?'contain':'cover'), backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundAttachment: "fixed" }} className='fondo-dev'>
                    <Stack w={'100%'} h='100%' justifyContent={'center'} alignItems='center' mb={12}>
                            <Flex mb={8} background={'radial-gradient(#00000021, #ffffff00)'} mt={6}>
                                <Text 
                                    fontWeight={"400"} 
                                    textTransform={"uppercase"} 
                                    fontSize={isGreaterThanMd?'5xl':'xl'}
                                    mb={0} 
                                    fontFamily={'CaslonCP'} 
                                    textAlign={'center'}
                                    color='white'
                                    
                                >
                                    {titleTop}
                                    <br />
                                    {titleBottom}
                                </Text>
                            </Flex>
                            <Flex justifyContent={"center"} display={isGreaterThanMd?'flex':'grid'} justifyItems={isGreaterThanMd?'':'center'}>
                                <Flex marginLeft={isGreaterThanMd?"1.5rem":'inherit'} w={isGreaterThanMd?'auto':'full'}>
                                    <ButtonActionModal
                                        title={'Quiero que me contacten'} 
                                        title_form={'form_quiero_saber_mas'}
                                        idFormHs={"4e271e4f-ec48-4a49-98d6-af4b257290c3"}
                                        thankYouPage={"thankyoupage-agenda-cita"}
                                        bgColor={"#FFFFFF"}
                                        hover={"gray.100"}
                                        fontColor='brand.500'
                                        txtSize={700}   
                                        p={'11px'}
                                        px={10} 
                                        size='30px'
                                        fontSize={isGreaterThanMd?'lg':'sm'}
                                        w={isGreaterThanMd?'auto':'full'}
                                        alignSelf={'center'}
                                    />
                                </Flex>
                            </Flex>
                        </Stack>
                    </div>
                </GridItem>
            </Grid>
        </Flex>
    );
}
 
export default Fondo2;