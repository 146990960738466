import React from 'react';
import { 
    Divider, 
    Flex,
    useMediaQuery, 
    useTheme
} from '@chakra-ui/react';
import Presentacion from '../../components/Presentacion';
import Amenidades from '../../components/Amenidades';
import Icons from '../../components/Icons';
import Recorrido from '../../components/Recorrido';
import Prototipos from '../../components/Prototipos';
import Cotizador from '../../components/Cotizador';
import Ubicacion from '../../components/Ubicacion';
import Fondo from "../../components/Fondo";
import Descripcion from '../../components/Descripcion';
import Mapa from '../../components/Mapa';
import Footer from '../../components/Footer';
import Video from '../../components/Video';
import Departamentos from '../../components/Departamentos';
import ButtonActionModal from '../../components/ButtonActionModal';

const Home = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    return ( 
        <>  
            <Flex position={'fixed'} right={{base: 8, md: 6}} bottom={5} zIndex={1}>
                <ButtonActionModal
                    idFormHs={"95996d46-d69a-4902-8418-c646fd067b5b"}
                    thankYouPage={"thankyoupage-contactar-whatsapp"}
                    isImage={true}
                    srcImg={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/redes-sociales/wa.svg'}
                    title={'Envíanos un Whatsapp'}
                    title_form={'form_whatsapp'}
                />
            </Flex>
            <Presentacion />
            <Amenidades />
            <Icons />
            <Fondo 
                imgBg={isGreaterThanMd ? 'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/banners/banner-1.webp' : 'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/banners/mobile/banner-1.webp'}
                titleTop={'Comienza a vivir en el corazón'}
                titleBottom={'del Country Club'}
            />
            <Video url={'https://www.youtube.com/embed/WylV4zv53M0'}/>
            <Recorrido />
            <Departamentos />
            <Fondo 
                imgBg={isGreaterThanMd ? 'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/banners/banner-2.webp' : 'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/banners/mobile/banner-2.webp'}
                titleTop={'Descubre Torre MM'}
                titleBottom={'y excede tus expectativas'}
            />
            <Prototipos />
            <Cotizador />
            <Ubicacion />
            <Fondo 
                imgBg={isGreaterThanMd ? 'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/banners/banner-3.webp' : 'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/banners/mobile/banner-3.webp'}
                titleTop={'Descubre Torre MM y forma parte del'}
                titleBottom={'proyecto más exclusivo del Country Club'}
            />
            <Descripcion />
            <Mapa />
            <Flex paddingLeft={"1.5rem"} paddingRight={"1.5rem"}>
                <Divider orientation='horizontal' />
            </Flex>
            <Footer />
        </>
    );
}
 
export default Home;