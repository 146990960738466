import React from 'react';
import { 
    Flex, 
    Text,
    Image
} from '@chakra-ui/react';
import './animation.css';

const IconsMb = () => {
    
    return ( 
        <Flex flexDirection={"column"}>
            <Flex w={"100%"} alignItems={"center"}>
                <Flex w={"50%"} flexDirection={"column"} alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                    <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/description/torre-bg.svg'} boxSize={'60px'} alt='torres'/>
                    <Text marginLeft={"0.5rem"} fontSize='sm' fontWeight={500} color='brand.500' textAlign={'start'}>
                        Un proyecto de <br /> Torre MIL500
                    </Text>
                </Flex>
                <Flex w={"50%"} flexDirection={"column"} alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                    <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/description/plans.svg'} boxSize={'60px'} alt='metros'/>
                    <Text marginLeft={"0.5rem"} fontSize='sm' fontWeight={500} color='brand.500' textAlign={'start'}>
                        Desde 41.57 m<sup>2</sup><br />hasta 143.77 m<sup>2</sup>
                    </Text>
                </Flex>
            </Flex>
            <Flex  w={"100%"} alignItems={"center"} mt={10}>
                <Flex w={"50%"} flexDirection={"column"} alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                    <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/description/recamaras.svg'} boxSize={'60px'} alt='recamaras'/>
                    <Text marginLeft={"0.5rem"} fontSize='sm' fontWeight={500} color='brand.500' textAlign={'start'}>
                        1, 2 y 3 <br /> habitaciones
                    </Text>
                </Flex>
                <Flex w={"50%"} flexDirection={"column"} alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                    <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/description/conectividad.svg'} boxSize={'60px'} alt='cercanias'/>
                    <Text marginLeft={"0.5rem"} fontSize='sm' fontWeight={500} color='brand.500' textAlign={'start'}>
                        Cercanías a lo <br /> mejor de la ciudad
                    </Text>
                </Flex>
            </Flex>
            <Flex w={"100%"} alignItems={"center"} mt={10}>
                <Flex w={"50%"} flexDirection={"column"} alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                    <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/description/amenidades.svg'} boxSize={'60px'} alt='amenidades'/>
                    <Text marginLeft={"0.5rem"} fontSize='sm' fontWeight={500} color='brand.500' textAlign={'start'}>
                        Más de 20 <br /> amenidades exclusivas
                    </Text>
                </Flex>
                <Flex w={"50%"} flexDirection={"column"} alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                    <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/description/estacionamiento.svg'} boxSize={'60px'} alt='estacionamiento'/>
                    <Text marginLeft={"0.5rem"} fontSize='sm' fontWeight={500} color='brand.500' textAlign={'start'}>
                        Estacionamiento <br /> subterráneo
                    </Text>
                </Flex>
            </Flex>
            <Flex w={"100%"} alignItems={"center"} mt={10} mb={10}>
                <Flex w={"100%"} flexDirection={"column"} alignItems={"center"} display={'flex'} textAlign='-webkit-center'>
                    <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/description/pin.svg'} boxSize={'60px'} alt='ubicacion'/>
                    <Text marginLeft={"0.5rem"} fontSize='sm' fontWeight={500} color='brand.500' textAlign={'start'}>
                        Ubicación <br /> premium
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
}
 
export default IconsMb;