import React, { useEffect } from 'react';
import { 
    Flex,
    Tab,
    Tabs,
    TabPanels,
    TabPanel,
    TabList,
    Grid,
    GridItem,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    TableContainer, 
    TableCaption,
    useMediaQuery, 
    useTheme,
    Text
} from '@chakra-ui/react';
import { list } from '../../resource';
import TableMobile from './TableMobile';

const Cotizador = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.lg})`);

    useEffect(() => {
        const observerL = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show-cotizador');
          } else {
            entry.target.classList.remove('show-cotizador');
          }
        })
        })
          
        const hiddenElementsL = document.querySelectorAll('.hidden-left-cotizador');
        hiddenElementsL.forEach((el) => observerL.observe(el));
          
        const observerR = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('show-cotizador');
            } else {
              entry.target.classList.remove('show-cotizador');
            }
          })
        })
          
        const hiddenElementsR = document.querySelectorAll('.hidden-right-cotizador');
        hiddenElementsR.forEach((el) => observerR.observe(el));
    }, []);

    return ( 
        <Flex
            color={"#545454"}
            width={"100%"}
            id='planes-pago'
            flexDirection={"column"}
        >
            <Text fontSize={isGreaterThanMd ? "5xl" : "3xl"} fontFamily={'CaslonCP'} textAlign={"center"} mb={10}>PLANES DE PAGO</Text>
            <Grid
                templateColumns={"repeat(1, 1fr)"}
                w={"100%"}
                display={"flex"}
                justifyContent={"center"}
                pb={8}
                >
                <GridItem w={isGreaterThanMd ? "50%" : "100%"}>
                    {isGreaterThanMd ?
                        <Tabs variant='enclosed'>
                            <TabList width={"max-content"} margin={"auto"}>
                                <Tab fontFamily={"Larsseit"} fontSize={"18px"} _selected={{ bg: "#545454", color: "#fff" }}>Contado</Tab>
                                <Tab fontFamily={"Larsseit"} fontSize={"18px"} _selected={{ bg: "#545454", color: "#fff" }}>Contado diferido</Tab>
                                <Tab fontFamily={"Larsseit"} fontSize={"18px"} _selected={{ bg: "#545454", color: "#fff" }}>Financiamiento A</Tab>
                                <Tab fontFamily={"Larsseit"} fontSize={"18px"} _selected={{ bg: "#545454", color: "#fff" }}>Financiamiento B</Tab>
                            </TabList>
                            <TabPanels>
                                {list.map((item, idx) => (
                                    <TabPanel key={idx}>
                                        <TableContainer height={"26rem"}>
                                            <Table size='lg'>
                                                <TableCaption fontFamily={"Larsseit"} fontSize={"12px"}>
                                                </TableCaption>
                                                <Thead>
                                                    <Tr>
                                                        <Th fontFamily={"Larsseit-Bold"} fontSize={"18px"} textTransform={"capitalize"} color={"#545454"}>Descuento</Th>
                                                        <Th fontFamily={"Larsseit-Bold"} fontSize={"18px"} color={"#545454"}>{item.num}</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {/* <Tr>
                                                        <Th fontFamily={"Larsseit-Bold"} fontSize={"18px"}>{item.pay}</Th>
                                                        <Th></Th>
                                                    </Tr> */}
                                                    {item.tab.map((element, idx1) => (
                                                        <Tr key={idx1}>
                                                            <Th fontFamily={"Larsseit-Thin"} color={"#545454"} fontSize={"18px"} textTransform={"inherit"}>{element.item1}</Th>
                                                            <Th fontFamily={"Larsseit-Thin"} color={"#545454"} fontSize={"18px"} textTransform={"inherit"}>{element.item2}</Th>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                        <Text
                                            fontFamily={"Larsseit"} 
                                            fontSize={"12px"}
                                            color={"#545454"}
                                            textAlign={"center"}
                                        >
                                            *LA PRESENTE POLITICA COMERCIAL LA CUAL DETALLA LAS DIFERENTES FORMAS DE PAGO ESTA SUJETA A CAMBIOS EN CUALQUIER MOMENTO Y SIN PREVIO AVISO EN FUNCIÓN DE LA DEMANDA, VALIDA SOLO PARA EL
                                            MOMENTO DE CONSULTA, este documento no es ni sustituye una cotización, el precio total de la operación se determinará en función de los montos variables por conceptos de descuentos, intereses, créditos directos o
                                            hipotecarios participantes así como gastos notariales según apliquen, los cuales pueden ser consultados y cotizados por su asesor inmobiliario de Grupo Guía”. para más información consulta nuestra pagina web www.grupoguia.mx , precios en M.N (MONEDA NACIONAL).*
                                        </Text>
                                    </TabPanel>
                                ))}
                            </TabPanels>
                        </Tabs> : <TableMobile />
                    }
                </GridItem>
            </Grid>
        </Flex>
    );
}
 
export default Cotizador;