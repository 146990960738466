import { Flex, Image, Text, Grid, GridItem} from '@chakra-ui/react';

import '../styles/recorrido.css';
import '../styles/styled.css';

export const listImages = [ 
    {
        id: 1,
        images: [
            <Flex justifyContent={'center'} placeItems={'start'} w='100%' h={'100%'} mx='auto'>
                <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/constructora/dubai-frame.svg'} objectFit='cover' w='auto' h={'100%'} alt='icon'/>
                <Text
                    display={'flex'}
                    position={'absolute'}
                    py={6}
                    px={{base: 0, md: 12}}
                    mb={0}
                    float={'right'}
                    color={'brand.500'}
                    fontWeight={700}
                    right={'65px'}
                >
                    Dubai Frame
                </Text>
            </Flex>,
            <Flex justifyContent={'center'} placeItems={'start'} w='100%' h={'100%'} mx='auto'>
                <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/constructora/cctv-pekin.svg'} objectFit='cover' w='auto' h={'100%'} alt='icon'/>
                <Text
                    display={'flex'}
                    position={'absolute'}
                    py={6}
                    px={{base: 0, md: 12}}
                    mb={0}
                    float={'right'}
                    color={'white'}
                    fontWeight={700}
                    right={'65px'}
                    textAlign={'right'}
                >
                    Edificio <br /> CCTV Pekín
                </Text>
            </Flex>,
            <Flex justifyContent={'center'} placeItems={'start'} w='100%' h={'100%'} mx='auto'>
                <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/constructora/jeddah.svg'} objectFit='cover' w='auto' h={'100%'} alt='icon'/>
                <Text
                    display={'flex'}
                    position={'absolute'}
                    py={6}
                    px={{base: 0, md: 12}}
                    mb={0}
                    float={'right'}
                    color={'brand.500'}
                    fontWeight={700}
                    right={'65px'}
                    textAlign={'right'}
                >
                    Jeddah <br /> International Airport
                </Text>
            </Flex>,
            <Flex justifyContent={'center'} placeItems={'start'} w='100%' h={'100%'} mx='auto'>
                <Image src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/constructora/renaissance-tower.svg'} objectFit='cover' w='auto' h={'100%'} alt='icon'/>
                <Text
                    display={'flex'}
                    position={'absolute'}
                    py={6}
                    px={{base: 0, md: 12}}
                    mb={0}
                    float={'right'}
                    color={'white'}
                    fontWeight={700}
                    right={'65px'}
                    textAlign={'right'}
                >
                    Dubai <br /> Renaissance tower
                </Text>
            </Flex>,
        
        ]
    },
    {
        id: 2,
        images: [
            <Flex width={"100%"} padding={"0rem"} my={10} justifyContent={"center"}>
                <Flex justifyContent={"center"} w='100%'>
                    <GridItem colSpan={1}>
                        <Image  
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/A1-A.webp'}
                            width='90%' 
                            height='auto' 
                            alt='icon'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Text color='brand.500' fontSize={{base: 'xl', md: '3xl'}} fontWeight={600} mb={0}>
                            Modelo A1-A
                        </Text>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={400} mb={4}>
                            Desde 67.61 m<sup>2</sup> hasta 68.05 m<sup>2</sup>
                        </Text>

                        <Text color='brand.500' fontSize={'xl'} fontWeight={700} mb={0}>
                        Descripción:
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Recámara principal con <br/> baño y vestidor
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - ½ baño
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Cocina
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Sala
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Comedor
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Lavandería
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 1 cajón de estacionamiento
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Flex justifyContent={"center"} w='100%'>
                    <GridItem colSpan={1}>
                        <Image  
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/B1-A.webp'}
                            width='100%' 
                            height='auto' 
                            alt='icon'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} pl={4}>
                        <Text color='brand.500' fontSize={'3xl'} fontWeight={600} mb={0}>
                            Modelo B1-A
                        </Text>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={400} mb={4}>
                            92.03 m<sup>2</sup>
                        </Text>

                        <Text color='brand.500' fontSize={'xl'} fontWeight={700} mb={0}>
                        Descripción:
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 recámaras con baño y vestidor
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - ½ baño
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Cocina
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Sala
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Comedor
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Lavandería
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 cajones de estacionamiento
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Flex justifyContent={"center"} w='100%'>
                    <GridItem colSpan={1}>
                        <Image  
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/B2-A.webp'}
                            width='100%' 
                            height='auto' 
                            alt='icon'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} pl={4}>
                        <Text color='brand.500' fontSize={'3xl'} fontWeight={600} mb={0}>
                            Modelo B2-A
                        </Text>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={400} mb={4}>
                            Desde 94.47 m<sup>2</sup> hasta 95.57 m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={700} mb={0}>
                        Descripción:
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 recámaras con baño y closet
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - ½ baño
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Cocina
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Sala
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Comedor
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Lavandería
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Terraza
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 cajones de estacionamiento
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
            <Flex justifyContent={"center"} w='100%'>
                <GridItem colSpan={1}>
                    <Image  
                        src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/B2-B.webp'}
                        width='100%' 
                        height='auto' 
                        alt='icon'
                    />
                </GridItem>
                <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} pl={4}>
                    <Text color='brand.500' fontSize={'3xl'} fontWeight={600} mb={0}>
                        Modelo B2-B
                    </Text>
                    <Text color='brand.500' fontSize={'xl'} fontWeight={400} mb={4}>
                        Desde 94.72 m<sup>2</sup> hasta 95.82 m<sup>2</sup> 
                    </Text>
                    <Text color='brand.500' fontSize={'xl'} fontWeight={700} mb={0}>
                    Descripción:
                    </Text>
                    <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                        - 2 recámaras con baño y closet
                    </Text>
                    <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                        - ½ baño
                    </Text>
                    <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                        - Cocina
                    </Text>
                    <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                        - Sala
                    </Text>
                    <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                        - Comedor
                    </Text>
                    <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                        - Lavandería
                    </Text>
                    <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                        - Terraza
                    </Text>
                    <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 cajones de estacionamiento
                    </Text>
                </GridItem>
            </Flex>
        </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Flex justifyContent={"center"} w='100%'>
                    <GridItem colSpan={1}>
                        <Image  
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/C1-B.webp'}
                            width='100%' 
                            height='auto' 
                            alt='icon'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} pl={4}>
                        <Text color='brand.500' fontSize={'3xl'} fontWeight={600} mb={0}>
                            Modelo C1-B
                        </Text>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={400} mb={4}>
                            Desde 124.44 m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={700} mb={0}>
                        Descripción:
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 3 recámaras con vestidor
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 3 Baños completos
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Cocina
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Sala
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Comedor
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Lavandería
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Terraza
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 cajones de estacionamiento
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Flex justifyContent={"center"} w='100%'>
                    <GridItem colSpan={1}>
                        <Image  
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/C2-B.webp'}
                            width='100%' 
                            height='auto' 
                            alt='icon'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} pl={4}>
                        <Text color='brand.500' fontSize={'3xl'} fontWeight={600} mb={0}>
                            Modelo C2-B
                        </Text>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={400} mb={4}>
                            Desde 123.35 m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={700} mb={0}>
                        Descripción:
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 recámaras con baño y vestidor
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - ½ baño
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Cocina
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Sala
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Comedor
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Lavandería
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Terraza
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 cajones de estacionamiento
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Flex justifyContent={"center"} w='100%'>
                    <GridItem colSpan={1}>
                        <Image  
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/E1-A.webp'}
                            width='90%' 
                            height='auto' 
                            alt='icon'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} pl={4}>
                        <Text color='brand.500' fontSize={'3xl'} fontWeight={600} mb={0}>
                            Modelo E1-A
                        </Text>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={400} mb={4}>
                            Desde 41.57 m<sup>2</sup> hasta 44.82m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={700} mb={0}>
                        Descripción:
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 1 recámara
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 1 baño completo
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Cocina
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Sala
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Comedor
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Lavandería
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 1 cajón de estacionamiento
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Flex justifyContent={"center"} w='100%'>
                    <GridItem colSpan={1}>
                        <Image  
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/F1.webp'}
                            width='90%' 
                            height='auto' 
                            alt='icon'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} pl={4}>
                        <Text color='brand.500' fontSize={'3xl'} fontWeight={600} mb={0}>
                            Modelo F1
                        </Text>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={400} mb={4}>
                            Desde 140.80 m&sup2;
                        </Text>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={700} mb={0}>
                        Descripción:
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 3 recámaras + Flex
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 4 baño completo
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Cocina
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Sala
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Comedor
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Lavandería
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Terraza
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 cajones de estacionamiento
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 1 Bodega
                        </Text>
                    </GridItem>
                </Flex>
            </Flex>,
        ],
        imagesMobile: [
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Image  
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/A1-A.webp'}
                            width='70%' 
                            height='auto' 
                            alt='icon'
                            mx='auto'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4}>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={600} mb={0}>
                            Modelo A1-A
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={4}>
                            Desde 67.61 m<sup>2</sup> hasta 68.05 m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={700} mb={0}>
                        Descripción:
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Recámara principal con <br/> baño y vestidor
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - ½ baño
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Cocina
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Sala
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Comedor
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Lavandería
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 1 cajón de estacionamiento
                        </Text>
                    </GridItem>
                </Grid>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Image  
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/B1-A.webp'}
                            width='100%' 
                            height='auto' 
                            alt='icon'
                            mx='auto'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4}>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={600} mb={0}>
                            Modelo B1-A
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={4}>
                            92.03 m<sup>2</sup>
                        </Text>

                        <Text color='brand.500' fontSize={'lg'} fontWeight={700} mb={0}>
                        Descripción:
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - 2 recámaras con baño y vestidor
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - ½ baño
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Cocina
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Sala
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Comedor
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Lavandería
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 cajones de estacionamiento
                        </Text>
                    </GridItem>
                </Grid>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Image  
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/B2-A.webp'}
                            width='90%' 
                            height='auto' 
                            alt='icon'
                            mx='auto'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4}>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={600} mb={0}>
                            Modelo B2-A
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={4}>
                            Desde 94.47 m<sup>2</sup> hasta 95.57 m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={700} mb={0}>
                        Descripción:
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - 2 recámaras con baño y closet
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - ½ baño
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Cocina
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Sala
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Comedor
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Lavandería
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Terraza
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 cajones de estacionamiento
                        </Text>
                    </GridItem>
                </Grid>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
            <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                    <Image  
                        src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/B2-B.webp'}
                        width='90%' 
                        height='auto' 
                        alt='icon'
                        mx='auto'
                    />
                </GridItem>
                <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4}>
                    <Text color='brand.500' fontSize={'xl'} fontWeight={600} mb={0}>
                        Modelo B2-B
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={4}>
                        Desde 94.72 m<sup>2</sup> hasta 95.82 m<sup>2</sup>
                    </Text>
                    <Text color='brand.500' fontSize={'lg'} fontWeight={700} mb={0}>
                    Descripción:
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                        - 2 recámaras con baño y closet
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                        - ½ baño
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                        - Cocina
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                        - Sala
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                        - Comedor
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                        - Lavandería
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                        - Terraza
                    </Text>
                    <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 cajones de estacionamiento
                    </Text>
                </GridItem>
            </Grid>
        </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Image  
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/C1-B.webp'}
                            width='90%' 
                            height='auto' 
                            alt='icon'
                            mx='auto'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4}>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={600} mb={0}>
                            Modelo C1-B
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={4}>
                            Desde 124.44 m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={700} mb={0}>
                        Descripción:
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - 3 recámaras con vestidor
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - 3 Baños completos
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Cocina
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Sala
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Comedor
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Lavandería
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Terraza
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 cajones de estacionamiento
                        </Text>
                    </GridItem>
                </Grid>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Image  
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/C2-B.webp'}
                            width='90%' 
                            height='auto' 
                            alt='icon'
                            mx='auto'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4}>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={600} mb={0}>
                            Modelo C2-B
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={4}>
                            Desde 123.35 m<sup>2</sup>
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={700} mb={0}>
                        Descripción:
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - 2 recámaras con baño y vestidor
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - ½ baño
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Cocina
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Sala
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Comedor
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Lavandería
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Terraza
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 cajones de estacionamiento
                        </Text>
                    </GridItem>
                </Grid>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
            <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                    <Image  
                        src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/E1-A.webp'}
                        width='90%' 
                        height='auto' 
                        alt='icon'
                        mx='auto'
                    />
                </GridItem>
                <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4}>
                    <Text color='brand.500' fontSize={'xl'} fontWeight={600} mb={0}>
                        Modelo E1-A
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={4}>
                        Desde Desde 41.57 m<sup>2</sup>  hasta 44.82 m<sup>2</sup>
                    </Text>
                    <Text color='brand.500' fontSize={'lg'} fontWeight={700} mb={0}>
                    Descripción:
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                        - 1 recámara
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                        - 1 baño completo
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                        - Cocina
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                        - Sala
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                        - Comedor
                    </Text>
                    <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                        - Lavandería
                    </Text>
                    <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 1 cajón de estacionamiento
                    </Text>
                </GridItem>
            </Grid>
            </Flex>,
            <Flex width={"100%"} padding={"0rem"} justifyContent={'right'} my={10}>
                <Grid templateColumns={"repeat(1, 1fr)"} w='90%'>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'}>
                        <Image  
                            src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/prototipos/F1.webp'}
                            width='90%' 
                            height='auto' 
                            alt='icon'
                            mx='auto'
                        />
                    </GridItem>
                    <GridItem colSpan={1} alignItems={'center'} alignSelf={'center'} px={6} pt={4}>
                        <Text color='brand.500' fontSize={'xl'} fontWeight={600} mb={0}>
                            Modelo F1
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={4}>
                            Desde 140.80 m&sup2;
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={700} mb={0}>
                        Descripción:
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - 3 recámaras + Flex
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - 4 baño completo
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Cocina
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Sala
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Comedor
                        </Text>
                        <Text color='brand.500' fontSize={'md'} fontWeight={400} mb={1}>
                            - Lavandería
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - Terraza
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 2 cajones de estacionamiento
                        </Text>
                        <Text color='brand.500' fontSize={'lg'} fontWeight={400} mb={1}>
                            - 1 Bodega
                        </Text>
                    </GridItem>
                </Grid>
            </Flex>,
        ]
    },
    {
        id: 3,
        images: [
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/roof.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"3rem"} justifyContent={'left'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/alberca.webp'} 
                        width='100' 
                        height='100' 
                        alt='icon'
                    />
                    <Text
                    display={'flex'}
                    justifyContent={'center'}
                    position={'absolute'}
                    color='white'
                    float='left'
                    fontSize={'md'}
                    fontWeight={'800'}
                    textShadow={'2px 2px 2px #000000'}
                    p={4}
                    pl={16}
                    >
                        Alberca
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/asadores.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/alberca.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"3rem"} justifyContent={'left'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/asadores.webp'} 
                        width='100' 
                        height='100' 
                        alt='icon'
                    />
                    <Text
                    display={'flex'}
                    justifyContent={'center'}
                    position={'absolute'}
                    color='white'
                    float='left'
                    fontSize={'md'}
                    fontWeight={'800'}
                    textShadow={'2px 2px 2px #000000'}
                    p={4}
                    pl={16}
                    >
                        Asadores
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/business-lounge.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/asadores.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"3rem"} justifyContent={'left'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/business-lounge.webp'} 
                        width='100' 
                        height='100' 
                        alt='icon'
                    />
                    <Text
                    display={'flex'}
                    justifyContent={'center'}
                    position={'absolute'}
                    color='white'
                    float='left'
                    fontSize={'md'}
                    fontWeight={'800'}
                    textShadow={'2px 2px 2px #000000'}
                    p={4}
                    pl={16}
                    >
                        Business Lounge
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/gym.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/business-lounge.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"3rem"} justifyContent={'left'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/gym.webp'} 
                        width='100' 
                        height='100' 
                        alt='icon'
                    />
                    <Text
                    display={'flex'}
                    justifyContent={'center'}
                    position={'absolute'}
                    color='white'
                    float='left'
                    fontSize={'md'}
                    fontWeight={'800'}
                    textShadow={'2px 2px 2px #000000'}
                    p={4}
                    pl={{xl: 24, md: 16}}
                    >
                        Gym
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/lobby.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/gym.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"3rem"} justifyContent={'left'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/lobby.webp'} 
                        width='100' 
                        height='100' 
                        alt='icon'
                    />
                    <Text
                    display={'flex'}
                    justifyContent={'center'}
                    position={'absolute'}
                    color='white'
                    float='left'
                    fontSize={'md'}
                    fontWeight={'800'}
                    textShadow={'2px 2px 2px #000000'}
                    p={4}
                    pl={16}
                    >
                        Lobby
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/motor-lobby.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/lobby.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"3rem"} justifyContent={'left'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/motor-lobby.webp'} 
                        width='100' 
                        height='100' 
                        alt='icon'
                    />
                    <Text
                    display={'flex'}
                    justifyContent={'center'}
                    position={'absolute'}
                    color='white'
                    float='left'
                    fontSize={'md'}
                    fontWeight={'800'}
                    textShadow={'2px 2px 2px #000000'}
                    p={4}
                    pl={16}
                    >
                        Motor Lobby
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/restaurante.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/motor-lobby.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"3rem"} justifyContent={'left'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/restaurante.webp'} 
                        width='100' 
                        height='100' 
                        alt='icon'
                    />
                    <Text
                    display={'flex'}
                    justifyContent={'center'}
                    position={'absolute'}
                    color='white'
                    float='left'
                    fontSize={'md'}
                    fontWeight={'800'}
                    textShadow={'2px 2px 2px #000000'}
                    p={4}
                    pl={16}
                    >
                        Social Room
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/roof.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon'  backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/restaurante.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon'  width={"60%"} padding={"3rem"} justifyContent={'left'}>
                    <img 
                        style={{ width: "100%" }} 
                        src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/roof.webp'} 
                        width='100' 
                        height='100' 
                        alt='icon'
                    />
                    <Text
                    display={'flex'}
                    justifyContent={'center'}
                    position={'absolute'}
                    color='white'
                    float='left'
                    fontSize={'md'}
                    fontWeight={'800'}
                    textShadow={'2px 2px 2px #000000'}
                    p={4}
                    pl={16}
                    >
                        Roof
                    </Text>
                </Flex>
                <Flex className='col3-mon'   backgroundImage={`linear-gradient(#535353a3, #535353a3), url('${'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/slider/alberca.webp'}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
        ]
    }
];