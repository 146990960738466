import { Icon } from "@chakra-ui/icons";

const ArrowRightIcon = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17 8l4 4m0 0l-4 4m4-4H3"
    />
  </Icon>
);

export default ArrowRightIcon;