import React, { useState } from 'react';
import { Flex, Text, Grid, GridItem, useMediaQuery, useTheme, IconButton, Stack } from '@chakra-ui/react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { listImages } from '../resource/list';
import "../styles/styled.css";
import "../styles/descripcion.css";

import ArrowLeftIcon from "../assets/svgs/ArrowLeftSVG";
import ArrowRightIcon from "../assets/svgs/ArrowRightSVG";


const Tya = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
    const [activeIndex, setActiveIndex] = useState(0);
    
    const slidePrev = () => {
        activeIndex===0?(
          setActiveIndex(listImages[1].images.length-1)):
        setActiveIndex(activeIndex - 1);
                };

    const slideNext = () => {
        activeIndex===listImages[1].images.length-1?(
          setActiveIndex(0))
            : setActiveIndex(activeIndex + 1)

      };

    return ( 
        
        <Flex id='desarrollador' marginTop={isGreaterThanMd?"6rem":"1rem"} marginBottom={isGreaterThanMd ? "1rem" : "10px"} justifyContent={"center"}>
            <Grid display={isGreaterThanMd ? "grid" : "flex"} flexDirection={"column-reverse"} className='section-grid-construvida' templateColumns={isGreaterThanMd? "repeat(5, 1fr)" : "repeat(1, 1fr)"}  px={isGreaterThanMd?40:8} maxW={'6xl'} margin='auto' pb={isGreaterThanMd?16:4} gap={6}>
                <GridItem colSpan={2} mb={isGreaterThanMd?0:16} alignSelf='start'>
                    <Flex width={"100%"}>
                        <Flex display={"block"} textAlign={isGreaterThanMd?'start':'justify'}>
                            <Stack>
                                <Grid templateColumns={'repeat(2, 1fr)'}>
                                    <GridItem colSpan={1}>
                                        <img src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/constructora/download+2.svg'} width={isGreaterThanMd?'120':'150'} height='200' alt='descripcion'/>
                                    </GridItem>
                                    <GridItem colSpan={1}>
                                        <img src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/constructora/donis-person.svg'} width={isGreaterThanMd?'120':'150'} height='200' alt='descripcion'/>
                                    </GridItem>

                                </Grid>
                            </Stack>

                            <Text marginTop={"1rem"} fontSize='17' fontWeight='700' pt={4} color='#545454' lineHeight={1.2}>
                                Un proyecto de talla mundial diseñado por el Arq. Fernando Donis
                            </Text>
                            <Text marginTop={"1rem"} fontSize='sm' fontWeight='400' color='#545454' lineHeight={1.2}>
                                Fernando Donis es un arquitecto mexicano reconocido mundialmente, quien ha colaborado de la mano con Rem Koolhaas y ha enmarcado grandes ciudades con su talento inigualable. Algunas de sus obras más destacadas:
                            </Text>
                        </Flex>
                    </Flex>
                </GridItem>
                <GridItem colSpan={3} mb={isGreaterThanMd?0:16} alignSelf='center' maxWidth={isGreaterThanMd?'100%':'90%'}>
                    <Flex className='section-construvida-carousel' 
                        flexDirection={"row"} 
                        width={"100%"} 
                        pb={isGreaterThanMd ? "1rem" : "0rem"}
                        justifyContent={isGreaterThanMd ? "end" : "center"}
                        alignItems='center'
                        placeContent={"center"}
                        >
                        <IconButton
                            icon={<ArrowLeftIcon color="black" />}
                            rounded="full"
                            border="0"
                            shadow="md"
                            transitionDuration=".3s"
                            _hover={{ shadow: "lg" }}
                            //disabled={page <= 1}
                            onClick={slidePrev}
                            position="relative"
                            right={{ base: "-4", md: -24, xl: -16 }}
                            bg="white"
                            zIndex="2"
                        />
                        <AliceCarousel
                            mouseTracking
                            //disableDotsControls
                            //animationType="fadeout" 
                            animationDuration={150}
                            disableButtonsControls
                            activeIndex={activeIndex}
                            items={listImages[0].images}
                            responsive={true}
                            controlsStrategy="responsive"
                            animationType='fadeout'
                            autoPlay={true}
                            autoPlayInterval={5000}
                            infinite={true}
                            keyboardNavigation={true}
                            disableDotsControls
                            autoHeight={false}
                            maxHeight="200px"
                        />
                        <IconButton
                            icon={<ArrowRightIcon color="black" />}
                            rounded="full"
                            border="0"
                            colorScheme="brand"
                            shadow="md"
                            transitionDuration=".3s"
                            _hover={{ shadow: "lg" }}
                            onClick={slideNext}
                            position="relative"
                            left={{ base: "-4", md: -24, xl: -16 }}
                            bg="white"
                            zIndex="2"
                        />                        
                    </Flex>
                </GridItem>
            </Grid>
        </Flex>
    );
}
 
export default Tya;