import React, { useState } from 'react';
import { Flex, Text, useMediaQuery, useTheme, IconButton } from '@chakra-ui/react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { listImages } from '../resource/list';

import ArrowLeftIcon from "../assets/svgs/ArrowLeftSVG";
import ArrowRightIcon from "../assets/svgs/ArrowRightSVG";

const Prototipos = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [activeIndex, setActiveIndex] = useState(0);

    const slidePrev = () => {
        activeIndex===0?(
          setActiveIndex(listImages[1].images.length-1)):
        setActiveIndex(activeIndex - 1);
                };

    const slideNext = () => {
        activeIndex===listImages[1].images.length-1?(
          setActiveIndex(0))
            : setActiveIndex(activeIndex + 1)

      };

    return ( 
        <Flex flexDirection={"column"} alignItems={"center"} id='prototipos'>
            <Flex alignItems={"center"} textAlign={"center"} flexDirection={"column"} marginBottom={"1rem"}>
                <Text 
                    color={"brand.400"}
                    textTransform={"uppercase"} 
                    fontWeight={"400"} 
                    fontSize={{base:'2xl', md:'5xl'}}
                    fontFamily={'CaslonCP'}
                >
                    Prototipos
                </Text>
                <Text color={"brand.400"} fontWeight='400' fontSize={isGreaterThanMd?'3xl':'sm'} letterSpacing={'-0.5px'}>
                    Vive como a ti te gusta
                </Text>
            </Flex>
            <Flex width={isGreaterThanMd?"90%":'95%'} direction="row" alignItems="center" placeContent={'center'} >
                <IconButton
                    icon={<ArrowLeftIcon color="white" />}
                    rounded="full"
                    border="0"
                    shadow="md"
                    transitionDuration=".3s"
                    _hover={{ shadow: "lg" }}
                    //disabled={page <= 1}
                    onClick={slidePrev}
                    position="relative"
                    right={{ base: "-6", md: 10 }}
                    bg="brand.500"
                    zIndex="2"
                />
                <Flex flexDirection={"column"} width={"90%"} marginBottom={isGreaterThanMd?"4rem":'2rem'}>
                    <AliceCarousel
                        mouseTracking
                        //disableDotsControls
                        //animationType="fadeout" 
                        animationDuration={800}
                        disableButtonsControls
                        activeIndex={activeIndex}
                        items={isGreaterThanMd?listImages[1].images:listImages[1].imagesMobile}
                        responsive={true}
                        controlsStrategy="responsive"
                        animationType='fadeout'
                        autoPlay={true}
                        autoPlayInterval={5000}
                        infinite={true}
                        keyboardNavigation={true}
                        disableDotsControls
                        autoHeight={false}
                        maxHeight="200px"
                    />
                </Flex>
                <IconButton
                    icon={<ArrowRightIcon color="white" />}
                    rounded="full"
                    border="0"
                    colorScheme="brand"
                    shadow="md"
                    transitionDuration=".3s"
                    _hover={{ shadow: "lg" }}
                    onClick={slideNext}
                    position="relative"
                    left={{ base: "-6", md: 10 }}
                    bg="brand.500"
                    zIndex="2"
                />
            </Flex>
        </Flex>
    );
}
 
export default Prototipos;