import React from 'react';
import { Flex, Stack, Text, Link, useMediaQuery, useTheme, Image } from '@chakra-ui/react';
import "../styles/footer.css";

const Footer = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    return ( 
        <Flex my={"3rem"} mx={isGreaterThanMd?"3rem":"1.2rem"} flexDirection={"column"}>
            <Flex display={isGreaterThanMd?'flex':'none'}>
                <Flex className='footer-logos' width={isGreaterThanMd?"33%":"100%"} height={"fit-content"}>
                    <Stack direction='row'>
                        <Text fontSize={'xs'} fontWeight='300' textAlign={'center'} pr={4}>
                            Una creación de:
                            <Image style={{ margin: "auto" }} src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/logos/favier.svg'} width='170' height='35' alt='Logo TyA'/>
                        </Text>
                        <Text fontSize={'xs'} fontWeight='300' textAlign={'center'} pr={4}>
                            Comercializado por:
                            <img style={{ height: "auto" }} src={'https://boomerang.s3.us-east-2.amazonaws.com/assets/icons/icon-guia-logo-r-1.svg'} width='160' height='75' alt='Logo Guia'/>
                        </Text>
                    </Stack>
                </Flex>
                <Flex className='footer-copyright-top' width={"33%"} justifyContent='center'>
                    <Text fontSize='sm' color='#545454' fontWeight={400}>
                        Copyright &#169; 2023 &#169; All Rights Reserved - Powered by Grupo Guia
                        <br />
                        <Link href='https://grupoguia.mx/Aviso-de-privacidad' isExternal>Aviso de privacidad</Link>
                    </Text>
                </Flex>
                <Flex className='footer-iconos'width={isGreaterThanMd?"33%":"100%"}  height={"fit-content"} justifyContent={"end"}>
                    <Stack>
                        <Text fontSize={'xs'} fontWeight='300' textAlign={'center'} pr={4}>
                            Certificado por:
                            <img style={{ height: "auto", maxWidth: '100px' }}  src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/logos/edge.svg'} width='160' height='75' alt='Logo Guia'/>
                        </Text>
                    </Stack>
                    <Stack justifyContent={"end"} direction='row'>
                        <Link href='https://www.facebook.com/torremm' isExternal>
                            <img style={{ marginRight: "1rem" }} src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/logos/icon-fb-torremm.svg'} width='30' height='30' alt='Facebook'/>
                        </Link>
                        <Link href='https://www.instagram.com/torremmg/' isExternal>
                            <img style={{ marginRight: "1rem" }} src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/logos/icon-ig-torremm.svg'} width='30' height='30' alt='Instagram'/>
                        </Link>
                        <Link href='https://share.hsforms.com/1TiceT-xISkmY1q9LJXKQww3j49k' isExternal>
                            <img style={{ marginRight: "1rem" }} src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/logos/icon-wpp-torremm.svg'} width='30' height='30' alt='Wpp'/>
                        </Link>
                    </Stack>
                </Flex>
            </Flex>
            <Flex display={isGreaterThanMd?'none':'flex'} justify={"center"}>
                <Stack direction='row' alignItems={'center'}>
                    <Text fontSize={'2xs'} fontWeight='300' textAlign={'center'} pr={2}>
                        Una creación de:
                        <Image style={{ margin: "auto", height: "auto", width: "20"}} src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/logos/favier.svg'} width='15' height='25' alt='Logo TyA'/>
                    </Text>
                    <Text fontSize={'2xs'} fontWeight='300' textAlign={'center'} pr={0}>
                        Comercializado por:
                        <img style={{ height: "auto" }} src={'https://boomerang.s3.us-east-2.amazonaws.com/assets/icons/icon-guia-logo-r-1.svg'} width='160' height='75' alt='Logo Guia'/>
                    </Text>
                </Stack>
            </Flex>
            <Stack justifyContent={"center"} direction='row' display={isGreaterThanMd?'none':'flex'}>
                <Text fontSize={'2xs'} fontWeight='300' textAlign={'center'} pr={4}>
                    Certificado por:
                    <img style={{ height: "auto", maxWidth: '80px' }}  src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/logos/edge.svg'} width='160' height='75' alt='Logo Guia'/>
                </Text>
            </Stack>
            <Flex display={isGreaterThanMd?'none':'flex'} pt={6} pb={5} alignSelf='center'>
                <Stack justifyContent={"center"} direction='row' alignItems={'center'}>
                    <Link href='https://www.facebook.com/torremm' isExternal>
                        <img style={{ marginRight: "1rem" }} src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/logos/icon-fb-torremm.svg'} width='42' height='42' alt='Facebook'/>
                    </Link>
                    <Link href='https://www.instagram.com/torremmg/' isExternal>
                        <img style={{ marginRight: "1rem" }} src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/logos/icon-ig-torremm.svg'} width='42' height='42' alt='Instagram'/>
                    </Link>
                    <Link href='https://share.hsforms.com/1TiceT-xISkmY1q9LJXKQww3j49k' isExternal>
                        <img style={{ marginRight: "1rem" }} src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/logos/icon-wpp-torremm.svg'} width='30' height='30' alt='Wpp'/>
                    </Link>
                </Stack>
            </Flex>
            <Flex className='footer-copyright-bottom' justifyContent={"center"} marginTop={isGreaterThanMd?"2rem":2}>
                <Flex textAlign={"center"} justifyContent={"center"}>
                    <Text fontSize={isGreaterThanMd?'md':'xs'} width={isGreaterThanMd?"80%":'100%'}>
                        Copyright &#169; 2023 &#169; All Rights Reserved - Powered by Grupo Guia
                        <br />
                        <Link href='https://grupoguia.mx/Aviso-de-privacidad' isExternal>Aviso de privacidad</Link>
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
}
 
export default Footer;