import React, { useState, useEffect } from 'react';
import { 
    Flex, 
    Text,
    useMediaQuery, 
    useTheme,
    Grid,
    GridItem
} from '@chakra-ui/react';

import "../styles/presentacion.css";
import "../styles/styled.css";

import Nav from './Nav';

const Presentacion = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [imgBg, setImgBg] = useState();
    const [active, setActive] = useState(1)
    const [title, setTitle] = useState('Invierte en lo mejor del');
    const [titleBold, setTitleBold] = useState("Country club • gdl");
    const [text, setText] = useState('Departamentos de lujo en preventa desde $4.1 MDP');
    const [price, setPrice] = useState('Invierte en un proyecto DEL COMPLEJO Américas MIL500. 3 RECÁMARAS DESDE $9.4 MDP*');
    
    const [nums, setNums] = useState(0);
    const [change, setChange] = useState(true);
    
    const changeImage = (num) => {
        switch (num) {
            case 1:
                if (isGreaterThanMd) {
                    setImgBg('https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/hero/banner-1.webp');
                    } else {setImgBg('https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/hero/mobile/banner-1.webp');}
                setTitle('Invierte en lo mejor del');
                setTitleBold('Country club • gdl');
                setText('Departamentos de lujo en preventa desde $4.1 MDP');
                setPrice('Invierte en un proyecto DEL COMPLEJO Américas MIL500. 3 RECÁMARAS DESDE $9.4 MDP*');
                setActive(1);
                break;
            case 2:
                if (isGreaterThanMd) {
                    setImgBg('https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/hero/banner-2.webp');
                    } else {setImgBg('https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/hero/mobile/banner-2.webp');}
                setTitle('Vive lo mejor del');
                setTitleBold('Country Club • GDL');
                setText('Departamentos High rise luxury');
                setPrice('2 RECÁMARAS DESDE $7.4 MDP*');
                setActive(2);
                break;
            case 3:
                if (isGreaterThanMd) {
                    setImgBg('https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/hero/banner-3.webp');
                    } else {setImgBg('https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/hero/mobile/banner-3.webp');}
                setTitle('Departamentos que lo');
                setTitleBold('tienen todo');
                setText('Un proyecto diseñado para superar todas tus expectativas');
                setPrice('ESTUDIO Y 1 RECÁMARA desde $4.2 MDP*');
                setActive(3);
                break;
            default:
                break;
        }
    }

    const changeImg = () => {
        setTimeout(() => {
            var sum = nums+1;
            setNums(sum);
            setChange(!change);
            if (nums === 3) {
                setNums(1);
            }
            changeImage(nums);
        }, 8000);
    }

    useEffect(() => {
        // Reacciona dependiendo al cambio de pantalla y asigna la imagén ajustada
        if (isGreaterThanMd) {
            setImgBg(`https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/hero/banner-${active}.webp`);
        } else {
            setImgBg(`https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/hero/mobile/banner-${active}.webp`);
        }
    }, [isGreaterThanMd]);

    useEffect(() => {
        changeImg();
    }, [change]);

    return ( 
        <>
            <Flex display={"block"}>
                <Nav />
            </Flex>
            <Flex padding={"1rem"}>
                <Flex
                    backgroundImage={`url(${imgBg})`}
                    backgroundSize={'cover'}
                    backgroundRepeat={'no-repeat'}
                    backgroundPosition={'center'}
                    height={"100vh"} 
                    borderRadius={"26px"}
                    minW={'100%'} 
                    maxW={'100%'} 
                    float='right'
                    justifyContent={"center"}
                    flexDirection={"column"} backgroundColor={'transparent'}
                >
                    <Flex justifyContent={"center"} width={"100%"} marginRight={"2rem"} mb={4} display={isGreaterThanMd?'none':'flex'} p={4}>
                        <Flex color={"white"} flexDirection={"column"}>
                            <Text 
                                fontWeight={"400"} 
                                textTransform={"uppercase"} 
                                fontSize='3xl' mb={0} 
                                fontFamily={'CaslonCP'}
                                textAlign={'center'}
                            >
                                {title}
                                <br />
                                {titleBold}
                            </Text>
                            <Text textAlign={"center"} ml={4} marginTop={"1rem"} marginBottom={"1rem"} fontFamily={"Larsseit"} fontWeight='700' fontSize={'xl'}>
                                {text}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex width={"100vw"} marginRight={"2rem"} mb={4} display={isGreaterThanMd?'flex':'none'} alignItems={'center'} pt={24} zIndex={9}>
                        <Grid templateColumns={'repeat(15, 1fr)'} color='white' w='full' alignItems={'center'}>
                            <GridItem colSpan={14} textAlign={'center'} w='90%' mx='auto'>
                                <Text 
                                    fontWeight={"400"} 
                                    textTransform={"uppercase"} 
                                    fontSize='6xl' 
                                    mb={0} 
                                    fontFamily={'CaslonCP'} 
                                    textAlign={'center'}
                                >
                                    {title}
                                </Text>
                                <Text 
                                    fontWeight={"400"} 
                                    textTransform={"uppercase"} 
                                    fontSize='5xl' 
                                    mb={0} 
                                    fontFamily={'CaslonCP'} 
                                    textAlign={'center'}
                                >
                                    {titleBold}
                                </Text>
                                <Text 
                                    marginTop={"0rem"} 
                                    marginBottom={"1rem"} 
                                    fontWeight='400' 
                                    fontSize={'3xl'}
                                    w='60%'
                                    mx='auto'
                                    fontFamily={"Larsseit"}
                                    textAlign={'center'}
                                >
                                    {text}
                                </Text>
                            </GridItem>
                        </Grid>
                    </Flex>
                    <Flex w='full' justifyContent={'center'} mb={28}>
                        <Flex m={isGreaterThanMd ? "0px" : "0px 2rem"} padding={"1rem"} width={isGreaterThanMd?"auto":"100%"} backgroundColor={"white"} justifyContent='center' borderBottomWidth={isGreaterThanMd?'0px':'2px'}>
                            {
                                isGreaterThanMd?
                                <>
                                    <Flex color={'brand.500'} flexDirection={"column"} textAlign={"center"} textTransform={"uppercase"}>
                                        <Text fontSize='24px' fontFamily={"Larsseit"}>
                                            {price}
                                        </Text>
                                        {
                                                <Text fontSize='14px' fontFamily='Larsseit' textTransform={"uppercase"}>
                                                    *Precios sujetos a cambio sin previo aviso. Sujeto a disponibilidad.
                                                </Text>
                                        }
                                    </Flex>
                                </>
                                :
                                <>
                                    <Flex color={'brand.500'} flexDirection={"column"} textAlign='center' alignItems={'center'}>
                                        <Text fontSize='18px' fontFamily={"Larsseit"} fontWeight={"800"} textTransform='uppercase'>
                                            {price}
                                        </Text>
                                        {
                                            active===3?
                                                <Text fontSize='12px' fontFamily='Larsseit' textTransform='uppercase'>
                                                    *Precios sujetos a cambio sin previo aviso. Sujeto a disponibilidad.
                                                </Text>
                                            : null
                                        }
                                    </Flex>
                                </>
                            }
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
}
 
export default Presentacion;